import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import {Button, Icon} from '@mui/material';
// hooks
import useLocales from '../../../../hooks/useLocales';
import Iconify from "../../../../components/Iconify";
import {SPACE_TYPE} from "../../../../utils/constants";

// ----------------------------------------------------------------------

BranchSpaceAutoFilter.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    init: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minWidth: PropTypes.string,
};

export default function BranchSpaceAutoFilter({
                                                  onChange,
                                                  minWidth = '250px',
                                                  options = [],
                                                  init,
                                                  type,
                                                  placeholder = 'calendar.selectVenue',
                                                  label = 'venues',
                                              }) {
    const { translate: t } = useLocales();
    const [val, setVal] = useState(init);

    useEffect(() => {
        if (!val && options.length) {
            setVal(options[0]?.id || '');
        }
    }, [options]);

    useEffect(() => {
        setVal(init || '');
    }, [init]);

    const handleChange = (event, newValue) => {
        const selectedValueId = newValue?.id || '';
        setVal(selectedValueId);
        onChange(selectedValueId);
    };

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option?.name || option?.id || '-'}
            value={options.find((option) => option.id === val) || null}
            onChange={handleChange}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    {...props}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        padding: '8px 12px',
                        overflow: 'visible', // Allow overflow for larger content
                    }}
                >

                    {/* Icon based on option.type */}
                    {option?.space_type ? (
                        <Icon sx={{ fontSize: 20 ,color:"#8C51F5"}}>
                             {option.space_type === SPACE_TYPE.MEETING_ROOM && <Iconify icon={'mdi:virtual-meeting'} size="large" sx={{ mr: 1 }} />}
                            {option.space_type === SPACE_TYPE.CREATIVE  && <Iconify icon={'fluent-emoji-high-contrast:studio-microphone'} size="large" sx={{ mr: 1 }} />}
                            {option.space_type === SPACE_TYPE.OFFICE && <Iconify icon={'guidance:office'} size="large" sx={{ mr: 1 }} />}
                        </Icon>
                    ) : null}

                    {/* Name and Status */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap:"10px",
                            alignItems:"center",
                            flexGrow: 1,
                            minWidth: 0, // Ensure it adapts to the parent's size
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                whiteSpace: 'normal', // Allow wrapping
                                wordBreak: 'break-word', // Break words if needed
                            }}
                        >
                            {option?.name || option?.id}
                        </Typography>
                        {'is_active' in option && option?.is_active !== undefined ? (
                            <Chip
                                label={option?.is_active ? t('Active') : t('Inactive')}
                                size="small"
                                sx={{
                                    backgroundColor: option?.is_active ? '#8C51F5' : '#FF4842',
                                    color: '#fff',
                                    marginTop: '4px',
                                    fontSize: '0.7rem',
                                    width:'60px'
                                }}
                            />
                        ) : null}
                    </Box>
                </Box>
            )}

            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={t(placeholder)}
                    size="small"
                    sx={{
                        minWidth: minWidth, // Minimum width of the TextField
                        '& .MuiInputBase-input': {
                            whiteSpace: 'normal', // Allow text wrapping
                            wordBreak: 'break-word', // Break long words
                            overflowWrap: 'break-word', // Handle very long strings gracefully
                        },
                        '& .MuiAutocomplete-input': {
                            display: 'block', // Ensure multiline rendering
                        },
                    }}
                />
            )}
            noOptionsText={
                type
                    ? `${t('Data not found')} \n ${t(`Try another ${type}`)}`
                    : t('Data not found')
            }
            isOptionEqualToValue={(option, value) => option.id === value?.id}
        />
    );
}
