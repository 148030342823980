import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    getOptionLabel: PropTypes.func,
    isOptionEqualToValue: PropTypes.func,
    onChange: PropTypes.func,
    label: PropTypes.string,
};

export default function RHFAutocomplete({
                                            name,
                                            options = [],
                                            getOptionLabel = (option) => option?.label || '',
                                            isOptionEqualToValue = (option, value) => option?.id === value?.id,
                                            onChange,
                                            label,
                                            ...other
                                        }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete
                    options={options}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    value={field.value}
                    onChange={(event, newValue) => {
                        console.log(newValue?.id,"newValue?.id")
                        field.onChange(newValue?.id || ''); // Update form value
                        if (onChange) {
                            onChange(newValue); // Trigger custom onChange handler if provided
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            fullWidth
                            error={!!error}
                            helperText={error?.message}
                            {...other}
                        />
                    )}
                />
            )}
        />
    );
}
