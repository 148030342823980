import React, { useEffect } from 'react';
import * as Yup from 'yup';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Card, Stack, Typography, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from '../../../redux/store';
import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';
import PhoneInputWrapper from '../../../components/PhoneInputWrapper';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import useSettings from '../../../hooks/useSettings';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useSnackbar } from 'notistack';
import { URL_ORGANIZATION } from 'src/utils/restApiUrls';
import useAuth from 'src/hooks/useAuth';
import {useNavigate,useParams} from "react-router-dom";
import {getSupportOptions} from "../venues/VenuesSlice";
import RHFAutocomplete from "../../../components/hook-form/RHFAutocomplete";
import {ROLE} from "../../../utils/constants";
import {getOrganisationsList} from "./OrganisationSlice";

Yup.addMethod(Yup.string, 'validatePhone', function (errorMessage) {
  return this.test('validate-phone-number', errorMessage, (value) => {
    return isValidPhoneNumber(value) || this.createError({ message: errorMessage });
  });
});

const PH_REGEX = /^\+92[0-9]{10}$/;
const COORDINATE_REGEX = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,16}/;
const WEB_URL_REGEX = /(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/;

const EditOrganisationUser = () => {
  const isDesktop = useResponsive('up', 'sm');
  const {themeStretch} = useSettings();
  const {user} = useAuth();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { id ,org_id} = useParams();
  const navigate = useNavigate();
  const {Translate, translate: t} = useLocales();
  const {enqueueSnackbar} = useSnackbar();
  const {organisationsList,selectedUser} = useSelector((state) => state.orginization);
  const {organisation_roles} = useSelector((state) => state.venueOnboardEdit);
  const UpdateBranchSchema = Yup.object().shape({
    name: Yup.string().notRequired(),
    phone_number: Yup.string().notRequired(),
    email: Yup.string().email(t('venueCreateEdit.error-emailGeneral')).notRequired(),
    role: Yup.string().required('Role is required'),
  });


  const defaultValues = {
    phone_number:selectedUser?.user?.phone_number,
    name:selectedUser?.user?.name,
    email:selectedUser?.user?.email,
    role:selectedUser?.role_type,
  };

  const methods = useForm({
    resolver: yupResolver(UpdateBranchSchema),
    defaultValues
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: {isSubmitting},
  } = methods;

  const onSubmit = async (formData) => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const response = await axios.put(
          URL_ORGANIZATION.edit.replace(":org_id", org_id).replace(":user_id",id),
          {
            role:formData?.role
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            }
          }
      );
      const {success, message} = response.data;
      if (success) {
        enqueueSnackbar(message, {preventDuplicate: false});
        navigate(PATH_DASHBOARD.organisation.list)
        reset();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An error occurred';
      enqueueSnackbar(errorMessage, {variant: 'error', preventDuplicate: false});
    }
  };


  useEffect(() => {
    if(!selectedUser){
      navigate(PATH_DASHBOARD.organisation.list)
    }
    dispatch(getSupportOptions());
  }, []);

  return (
      <Page title={t('global.venueSettings')}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
              heading={'Edit User'}
              links={[
                {name: 'User', href: PATH_DASHBOARD.organisation.list},
                {name: 'Edit User'},
              ]}
          />
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Card sx={{p: 3}}>
                  <Box
                      sx={{
                        display: 'grid',
                        rowGap: 3,
                        columnGap: 1,
                        gridTemplateColumns: {xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)'},
                      }}
                  >

                    <RHFTextField name='name' label={t('global.name')} disabled={id}/>
                    <RHFTextField name='email' label={t('venueCreateEdit.form-emailGeneral')} disabled={id}/>
                    <Controller
                        name='phone_number'
                        control={control}
                        type={'phone'}
                        disabled={id}
                        render={({field: {value, onChange}, fieldState: {error}}) => (
                            <PhoneInputWrapper
                                label='Mobile'
                                className={error ? 'input-error gradientbg' : 'gradientbg'}
                            >
                              <span className='mobile-label'>{t('form.mobile')}</span>
                              <PhoneInput
                                  id='phone_number'
                                  value={value}
                                  defaultCountry='AE'
                                  onChange={onChange}
                                  disabled={id}
                              />
                              {error && (
                                  <Typography variant='caption' color='error.main' sx={{mt: 1, ml: 2}} paragraph>
                                    {error.message}
                                  </Typography>
                              )}
                            </PhoneInputWrapper>
                        )}
                    />
                    <RHFSelect name='role' label='User Role'>
                      <option value=""/>
                      {organisation_roles?.length ?
                          organisation_roles?.map(({label, value}) => <option value={value}>{label}</option>)
                          : null}
                    </RHFSelect>
                  </Box>
                  <Stack spacing={3} alignItems='flex-end' sx={{mt: 3}}>
                    <LoadingButton type='submit' variant='contained' loading={isSubmitting}>
                      <Translate i18nKey='global.btn-saveChanges'/>
                    </LoadingButton>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
        </Container>
      </Page>
  );
};

export default EditOrganisationUser;
