import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Stack, Typography, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
import { MuiOtpInput } from "mui-one-time-password-input";
import { Controller } from "react-hook-form";
import Box from "@mui/material/Box";

const GENERAL_ERROR = 'Could not process this request.';

OTP.propTypes = {
  changeScreen: PropTypes.func,
  defaultScreen: PropTypes.string,
  emailOrMobile: PropTypes.string,
};

export default function OTP({ changeScreen, defaultScreen, emailOrMobile }) {
  const { Translate } = useLocales();
  const { verifyOTPLogin, login, user } = useAuth();
  const [otp, setOtp] = useState('');
  const [resendTimeout, setResendTimeout] = useState(30);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendClickCount, setResendClickCount] = useState(0);
  const [showResendText, setShowResendText] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const OTPSchema = Yup.object().shape({
    otp: Yup.string()
        .required('OTP is required')
        .length(4, 'OTP must be 4 digits'),
  });

  const methods = useForm({
    resolver: yupResolver(OTPSchema),
    defaultValues: { otp: '' },
  });

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { isSubmitting ,errors},
  } = methods;

  const onSubmit = async (data) => {
    setError(false);
    const postData = { token: user?.token, otp: data.otp };
    try {
      const responseData = await verifyOTPLogin(postData);
      if (responseData.error?.message) {
        setError(responseData.error);
      } else if (responseData.data?.token) {
        // Successfully logged in
      } else {
        setError({ message: GENERAL_ERROR });
      }
    } catch (err) {
      console.error('Error submitting OTP:', err);
      setError({ message: err.response?.data?.message || GENERAL_ERROR });
    }
  };

  const handleResendOTP = async () => {
    try {
      clearErrors()
      setResendDisabled(true);
      const postData = { signup_uuid: user.signupUuid };
      const data = await login(emailOrMobile);
      if (data.status) {
        setResendTimeout(5)
        setSuccess({ title: 'Success', message: 'OTP sent' });

        setTimeout(() => {
          setSuccess(false); // Reset success state
        }, 5000); // 30 seconds in milliseconds

      } else {
        setError({ title: 'Error', message: 'Error while sending OTP' });
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setResendTimeout(resendTimeout - 1);
    }, 1000);
    if (resendTimeout === 0) {
      setResendDisabled(false);
      clearTimeout(timeout);
    }
  }, [resendTimeout]);

  const digitStyles = {
    "& .MuiOutlinedInput-root": {
      width: "3.625rem",
      height: "5.063rem",
      fontSize: "2rem",
      textAlign: "center",
      borderRadius: "0.5rem",
      backgroundColor: "transparent", // Ensure a visible background
      color: "#000000", // Set text color
    },
    "& .MuiOutlinedInput-input": {
      textAlign: "center", // Ensure the text is centered inside the input
      color: "#000000", // Explicit text color
    },
  };


  return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!!error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error.message}
              </Alert>
          )}
          {!!errors?.otp && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errors?.otp.message}
              </Alert>
          )}
          {!!success && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {success.message}
              </Alert>
          )}
          <Stack direction="row" justifyContent="center" sx={{ my: 4 }}>
            <Controller
                name="otp"
                control={control}
                render={({ field }) => (
                    <Box>
                      <MuiOtpInput sx={digitStyles} {...field} length={4} />
                    </Box>
                )}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" sx={{ pt: 4, mb: 4 }}>
            <LoadingButton
                onClick={handleResendOTP}
                disabled={resendDisabled}
                sx={{ fontSize: '1rem', fontWeight: '200' }}
            >
              {resendTimeout ? `Resend OTP (0:${resendTimeout.toString().padStart(2, '0')})` : 'Resend OTP'}
            </LoadingButton>
            <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
              Verify
            </LoadingButton>
          </Stack>
        </form>
      </>
  );
}
