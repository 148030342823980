import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Stack, Button, Box, Tooltip, Typography, IconButton, ToggleButton } from '@mui/material';
// utils
import {fDate, fDateShort /* , fDisplay, fToNextWeekCal */} from '../../../../utils/formatTime'; // TODO: check calendar date display on header - to display month instead of whole date or switch to day view as default
// hooks
import useResponsive from '../../../../hooks/useResponsive';
// components
import Iconify from '../../../../components/Iconify';
// hooks
import useLocales from '../../../../hooks/useLocales';
import useAuth from "../../../../hooks/useAuth";
import {ROLE} from "../../../../utils/constants";


// ----------------------------------------------------------------------

const VIEW_OPTIONS = [
  { value: 'dayGridMonth', label: 'Month', icon: 'ic:round-view-module', translationKey: 'calendar.month' },
  { value: 'timeGridWeek', label: 'Week', icon: 'ic:round-view-week', translationKey: 'calendar.week' },
  { value: 'timeGridDay', label: 'Day', icon: 'ic:round-view-day', translationKey: 'calendar.day' },
  { value: 'listWeek', label: 'Agenda', icon: 'ic:round-view-agenda', translationKey: 'calendar.agenda' },
];

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2.5),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

// ----------------------------------------------------------------------

CalendarToolbar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onBlockTime: PropTypes.func,
  add: PropTypes.bool,
  onToday: PropTypes.func,
  onNextDate: PropTypes.func,
  onPrevDate: PropTypes.func,
  onChangeView: PropTypes.func,
  children: PropTypes.object,
  view: PropTypes.oneOf(['dayGridMonth', 'timeGridWeek', 'timeGridDay', 'listWeek']),
};

export default function CalendarToolbar({ date,add =false ,view, onAdd, onBlockTime, onToday, onNextDate, onPrevDate, onChangeView, children }) {
  const isDesktop = useResponsive('up', 'sm');
  const { Translate, translate: t, currentLang } = useLocales();
  const theme = useTheme();

  return (
    <RootStyle>
      <Stack direction={isDesktop ? 'row' : 'column'} sx={{ width: '100%' ,display:'flex',alignItems:'center',flexWrap:"wrap",gap:"1rem"}} spacing={0.5}>
          <Box>{children}</Box>
          <Box sx={{ mx: 'auto', width: '5%', ...(isDesktop && { display: 'block'} || {})}} />
          {/* <Box> */}

          {/* </Box> */}
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>

            <Stack direction="row" alignItems="center" spacing={isDesktop ? 2 : 0}>
              {add ?
                  <Tooltip title={t('calendar.tooltip-block')} placement="bottom">
                    <Button onClick={onAdd} color="primary" size="small" variant="contained" sx={{ borderRadius: '10px', ...(isDesktop && { px: 2} || {}) }}>
                      <Translate i18nKey='global.add' />
                    </Button>
                  </Tooltip>
              :  null}
              <Tooltip title={t('calendar.tooltip-block')} placement="bottom">
                <Button onClick={onBlockTime} color="error" size="small" variant="contained" sx={{ borderRadius: '10px', ...(isDesktop && { px: 2} || {}) }}>
                  <Translate i18nKey='calendar.btn-block' />
                </Button>
              </Tooltip>
            </Stack>
            <Stack  direction="row" alignItems="center" spacing={isDesktop ? 2 : 0}>
              <IconButton onClick={onPrevDate}>
                <Iconify
                    icon={theme.direction === 'ltr' ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'}
                    width={20}
                    height={20}
                />
              </IconButton>

              <Typography variant="h5">{fDateShort(date, currentLang.value)} {/* <DisplayPeriod start={date} view={view} /> */''}</Typography>

              <IconButton onClick={onNextDate}>
                <Iconify
                    icon={theme.direction === 'ltr' ? 'eva:arrow-ios-forward-fill' : 'eva:arrow-ios-back-fill'}
                    width={20}
                    height={20}
                />
              </IconButton>
            </Stack>
            {isDesktop && (
              <Box sx={{ display: 'flex',alignItems:"center"}} gap="10px">
                  <Button size="small" color="error" onClick={onToday}>
                    <Translate i18nKey='duration.today' />
                  </Button>
                  <Stack direction="row" spacing={0.5}>
                    {VIEW_OPTIONS.map((viewOption) => (
                      <Tooltip key={viewOption.value} title={t(viewOption.translationKey)}>
                        <ToggleButton
                          value={view}
                          selected={viewOption.value === view}
                          onChange={() => onChangeView(viewOption.value)}
                          sx={{ width: 32, height: 32, padding: 0, border: 0 }}
                        >
                          <Iconify icon={viewOption.icon} width={20} height={20} />
                        </ToggleButton>
                      </Tooltip>
                    ))}
                  </Stack>
              </Box>
            )}

          </Box>
      </Stack>
    </RootStyle>
  );
}
/*  // TODO: check calendar date display on header - to display month instead of whole date or switch to day view as default
const DisplayPeriod = function ({ start, view }) {
  // TODO:  get start and end from calendar and cal duration
  let next = null;
  let str = '';
  switch(view){
    case 'dayGridMonth': str = fDisplay(start, 'MMMM yyyy');
                         break;
    case 'timeGridWeek':
    case 'listWeek'    : next = fToNextWeekCal(start, 1);
                        console.log("start date::: ",fDisplay(start, 'd'));
                        console.log("end date::: ",fDisplay(next, 'd'));
                         str = `${fDisplay(start, 'd')}-${fDisplay(next, 'd MMM yyyy')}`;
                         break;
    case 'timeGridDay':
    default: str = fDate(start);
  }
  return <span>{str}</span>;

} */
