import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default function RHFSelect({ name, children,onChange, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          value={field.value || ''}
          fullWidth
          SelectProps={{ native: true }}
          error={!!error}
          helperText={error?.message}
          onChange={(e) => {
            field.onChange(e); // Update the form state
            if (onChange) {
              onChange(e); // Call the passed onChange prop if it exists
            }
          }}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}
